// @media screen and (max-width: 1023px) and (min-width: 426px) {
//   .login-container {
//     background-image: url("../../assets/images/loginbg.jpeg");
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     width: 100vw;
//     height: 100vh;
//     position: absolute;
//     top: 0;
//     right: 0;
//     opacity: 0.9;
//     .fields-holder {
//       margin: auto;
//       text-align: center;
//       .login-paper {
//         width: 50%;
//         margin: auto;
//         h3 {
//           font-size: 26px;
//           padding-top: 16px;
//         }
//         form {
//           padding: 24px;
//           .username-field {
//             display: flex;
//             flex-direction: row-reverse;
//           }
//           .MuiGrid-item {
//             margin: 24px 0;
//             width: 100%;
//             .login-button {
//               // min-width: 93%;
//               width: inherit;
//               padding: 10px;
//               background: linear-gradient(
//                 180deg,
//                 hsla(43, 79%, 74%, 1) 0%,
//                 hsla(43, 27%, 37%, 1) 100%
//               );

//               background: -moz-linear-gradient(
//                 180deg,
//                 hsla(43, 79%, 74%, 1) 0%,
//                 hsla(43, 27%, 37%, 1) 100%
//               );

//               background: -webkit-linear-gradient(
//                 180deg,
//                 hsla(43, 79%, 74%, 1) 0%,
//                 hsla(43, 27%, 37%, 1) 100%
//               );
//               color: #1c1c1c;
//               font-size: 20px;
//               font-weight: bold;
//             }
//             .icon-button {
//               background-color: #f3fd82;
//               color: #1c1c1c;
//               width: 50px;
//               height: 50px;
//               margin: 8px;
//               top: 50%;
//               transform: translateY(-12%);
//             }
//           }
//         }
//       }
//       // .login-paper::after {
//       //   width: 50%;
//       //   margin: auto;
//       //   content: "";
//       //   background-color: rgba($color: #000, $alpha: 0.4);
//       //   height: 100%;
//       //   position: absolute;
//       //   top: 0;
//       //   right: 0;
//       // }
//     }
//     .logo-holder {
//       display: none;
//       h3 {
//         margin: auto;
//         color: white;
//         width: 100px;
//         height: 100px;
//         border-radius: 50%;
//         border: 1px solid gold;
//         text-align: center;
//         position: absolute;
//         top: 30%;
//         left: 30%;
//         background-image: url("../../assets/images/logo.jpg");
//         background-position: center;
//       }
//     }
//   }
// }

@media screen and (max-width: 425px) {
  .login-container {
    background-image: url("../../assets/images/loginbg.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.9;
    .fields-holder {
      margin: auto;
      text-align: center;
      .login-paper {
        width: 90%;
        margin: auto;
        h3 {
          font-size: 20px;
          padding-top: 8px;
        }
        form {
          padding: 4px;
          .username-field {
            display: flex;
            flex-direction: row-reverse;
          }
          .MuiGrid-item {
            margin: 24px 0;
            width: 100%;
            .MuiFormControl-root {
              width: 75%;
            }
            .login-button {
              // min-width: 93%;
              width: 93%;
              padding: 10px;
              background: linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );

              background: -moz-linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );

              background: -webkit-linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );
              color: #1c1c1c;
              font-size: 20px;
              font-weight: bold;
            }
            .icon-button {
              background-color: #f3fd82;
              color: #1c1c1c;
              width: 50px;
              height: 50px;
              margin: 8px;
              top: 50%;
              transform: translateY(-12%);
            }
          }
        }
      }
      // .login-paper::after {
      //   width: 50%;
      //   margin: auto;
      //   content: "";
      //   background-color: rgba($color: #000, $alpha: 0.4);
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      // }
    }
    .logo-holder {
      display: none;
      h3 {
        margin: auto;
        color: white;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid gold;
        text-align: center;
        position: absolute;
        top: 30%;
        left: 30%;
        background-image: url("../../assets/images/logo.jpg");
        background-position: center;
      }
    }
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .login-container {
    background-image: url("../../assets/images/loginbg.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.9;
    .fields-holder {
      margin: auto;
      text-align: center;
      .login-paper {
        width: 90%;
        margin: auto;
        h3 {
          font-size: 20px;
          padding-top: 8px;
        }
        form {
          padding: 4px;
          .username-field {
            display: flex;
            flex-direction: row-reverse;
          }
          .MuiGrid-item {
            margin: 24px 0;
            width: 100%;
            .MuiFormControl-root {
              width: 75%;
            }
            .login-button {
              // min-width: 93%;
              width: 90%;
              padding: 10px;
              background: linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );

              background: -moz-linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );

              background: -webkit-linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );
              color: #1c1c1c;
              font-size: 20px;
              font-weight: bold;
            }
            .icon-button {
              background-color: #f3fd82;
              color: #1c1c1c;
              width: 50px;
              height: 50px;
              margin: 8px;
              top: 50%;
              transform: translateY(-12%);
            }
          }
        }
      }
      // .login-paper::after {
      //   width: 50%;
      //   margin: auto;
      //   content: "";
      //   background-color: rgba($color: #000, $alpha: 0.4);
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      // }
    }
    .logo-holder {
      display: none;
      h3 {
        margin: auto;
        color: white;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid gold;
        text-align: center;
        position: absolute;
        top: 30%;
        left: 30%;
        background-image: url("../../assets/images/logo.jpg");
        background-position: center;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .login-container {
    background-image: url("../../assets/images/loginbg.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    // position: absolute;
    // top: 0;
    // right: 0;
    opacity: 0.9;
    .fields-holder {
      margin: auto;
      text-align: center;
      .login-paper {
        width: 50%;
        margin: auto;
        h3 {
          font-size: 26px;
          padding-top: 16px;
        }
        form {
          padding: 24px;
          .username-field {
            display: flex;
            flex-direction: row-reverse;
          }
          .MuiGrid-item {
            margin: 24px 0;
            width: 100%;
            .MuiFormControl-root {
              width: 75%;
            }
            .login-button {
              width: 90%;
              padding: 10px;
              background: linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );

              background: -moz-linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );

              background: -webkit-linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );
              color: #1c1c1c;
              font-size: 20px;
              font-weight: bold;
            }
            .icon-button {
              background-color: #f3fd82;
              color: #1c1c1c;
              width: 50px;
              height: 50px;
              margin: 8px;
              top: 50%;
              transform: translateY(-12%);
            }
          }
        }
      }
      // .login-paper::after {
      //   width: 50%;
      //   margin: auto;
      //   content: "";
      //   background-color: rgba($color: #000, $alpha: 0.4);
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      // }
    }
    .logo-holder {
      h3 {
        margin: auto;
        color: white;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid gold;
        text-align: center;
        position: absolute;
        top: 30%;
        left: 10%;
        background-image: url("../../assets/images/logo.jpg");
        background-position: center;
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .login-container {
    background-image: url("../../assets/images/loginbg.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    // position: absolute;
    // top: 0;
    // right: 0;
    opacity: 0.9;
    .fields-holder {
      margin: auto;
      text-align: center;
      .login-paper {
        width: 50%;
        margin: auto;
        h3 {
          font-size: 26px;
          padding-top: 16px;
        }
        form {
          padding: 24px;
          .username-field {
            display: flex;
            flex-direction: row-reverse;
          }
          .MuiGrid-item {
            margin: 24px 0;
            width: 100%;
            .MuiFormControl-root {
              width: 75%;
            }
            .login-button {
              width: 93%;
              padding: 10px;
              background: linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );

              background: -moz-linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );

              background: -webkit-linear-gradient(
                180deg,
                hsla(43, 79%, 74%, 1) 0%,
                hsla(43, 27%, 37%, 1) 100%
              );
              color: #1c1c1c;
              font-size: 20px;
              font-weight: bold;
            }
            .icon-button {
              background-color: #f3fd82;
              color: #1c1c1c;
              width: 50px;
              height: 50px;
              margin: 8px;
              top: 50%;
              transform: translateY(-12%);
            }
          }
        }
      }
      // .login-paper::after {
      //   width: 50%;
      //   margin: auto;
      //   content: "";
      //   background-color: rgba($color: #000, $alpha: 0.4);
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      // }
    }
    .logo-holder {
      h3 {
        margin: auto;
        color: white;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid gold;
        text-align: center;
        position: absolute;
        top: 30%;
        left: 30%;
        background-image: url("../../assets/images/logo.jpg");
        background-position: center;
      }
    }
  }
}
