.tab-pane {
  width: 15vw;
  position: fixed;
  right: 6vw;
  top: 0;
  height: 100vh;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  display: block;
  transition: 0.3s all;
  animation-duration: 0.8s;
  animation-name: slideinRight;
  background-color: rgba(#fff, 0.5);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  z-index: 10;
  ul {
    transition: 1s all;
    animation-duration: 1s;
    animation-name: show;
  }
}

.tab-pane-closed {
  width: 0;
  position: fixed;
  right: 6vw;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  display: none;
  transition: 0.3s all;
  animation-duration: 0.8s;
  animation-name: slideinLeft;
  ul {
    display: none;
    transition: 0.5s all;
    animation-duration: 0.8s;
    animation-name: hide;
  }
}

@media screen and (max-width: 769px) {
  .tab-pane {
    display: none;
  }
}

@keyframes slideinLeft {
  100% {
    width: 0;
    display: none;
  }
}

@keyframes slideinRight {
  from {
    width: 0;
    display: none;
  }

  to {
    width: 15vw;
    display: block;
  }
}

@keyframes show {
  from {
    display: none;
  }
  to {
    display: flex;
  }
}

@keyframes hide {
  from {
    display: flex;
  }
  to {
    display: none;
  }
}
