@media screen and (min-width: 769px) {
  .mobile-navbar {
    display: none;
  }
}

// @media screen and (max-width: 768px) and (min-width: 551px) {
//   .mobile-navbar {
//     position: fixed;
//     top: 0;
//     right: 0;
//     height: 100vh;
//     width: 21vw;
//     border-left: 1px solid rgba(0, 0, 0, 0.3);
//     z-index: 10;
//     background-color: white;
//     ul {
//       padding-right: 16px;
//     }
//   }
// }

@media screen and (min-width: 320px) and (max-width: 768px) {
  .mobile-navbar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 40vw;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    z-index: 15;
    background-color: white;
    ul {
      padding-right: 8px;
    }
  }
  .active-link {
    background-color: rgba($color: #f3fd82, $alpha: 0.8);
  }
  .hide {
    display: none;
  }
}
