.desktop-navbar-closed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6vw;
  height: 100vh;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: rgba(#fff, 0.5);
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  z-index: 10;
  .desktop-nav-item {
    padding: 8px 16px;
    .MuiIconButton-root {
      padding: 16px;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .desktop-nav-item-active {
    padding: 8px 16px;
    .MuiIconButton-root {
      padding: 16px;
      background-color: rgba(#f3fd82, 0.6);
    }
  }
}
.desktop-navbar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6vw;
  height: 100vh;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: rgba(#fff, 0.5);
  z-index: 10;
  .desktop-nav-item {
    padding: 8px 16px;
    .MuiIconButton-root {
      padding: 16px;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .desktop-nav-item-active {
    padding: 8px 16px;
    .MuiIconButton-root {
      padding: 16px;
      background-color: rgba(#f3fd82, 0.6);
    }
  }
}

@media screen and (max-width: 768px) {
  .desktop-navbar,
  .desktop-navbar-closed {
    display: none;
  }
}
