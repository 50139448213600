.parent-container {
  position: absolute;
  right: 22vw;
  top: 11vh;
  width: calc(100% - 23vw);
  height: calc(100% - 14vh);
  z-index: 10;
  background-color: rgba(#fff, 0.5);
  border-radius: 4px;
  margin-top: 16px;
  margin-left: 16px;
}

.parent-container-extended {
  position: absolute;
  right: 6vw;
  top: 11vh;
  width: calc(100% - 8vw);
  height: calc(100% - 14vh);
  z-index: 10;
  background-color: rgba(#fff, 0.5);
  border-radius: 4px;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .parent-container,
  .parent-container-extended {
    background-color: rgba(#fff, 0.5);
    overflow-x: hidden;
    margin: 16px 0;
    width: 100%;
    border-radius: 4px;
    right: 0;
  }
}
