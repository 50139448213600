.main-layout {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}
.main-layout::after {
  content: "";
  background: url("../../assets/images/parent-container-bg.jpeg") center
    no-repeat;
  background-size: cover;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 1;
  filter: blur(2px);
}
