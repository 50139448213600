@font-face {
  font-family: "Vazir-Medium-FD";
  src: url("../assets/fonts/Vazir-Medium-FD.woff2") format("woff2"),
    url("../assets/fonts/Vazir-Medium-FD.woff") format("woff"),
    url("../assets/fonts/Vazir-Medium-FD.ttf") format("truetype");
}

* {
  font-family: "Vazir-Medium-FD" !important;
}
