.topbar {
  position: fixed;
  top: 0;
  right: 21vw;
  height: 10vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: calc(100% - 23vw);
  background-color: rgba(#fff, 0.5);
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 14px;
  border-radius: 4px;
  z-index: 10;
  .avatar {
    margin: 8px;
    max-width: 3%;
  }
  .date-time {
    margin-right: auto;
    margin-left: 8px;
    max-width: 5%;
  }
}

.topbar-extended {
  position: fixed;
  top: 0;
  right: 6vw;
  height: 10vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: calc(100% - 8vw);
  background-color: rgba(#fff, 0.5);
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  z-index: 10;
  .avatar {
    margin: 8px;
    max-width: 3%;
  }
  .date-time {
    margin-right: auto;
    max-width: 5%;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .topbar,
  .topbar-extended {
    position: fixed;
    top: 0;
    right: 0;
    height: 10vh;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    background-color: rgba(#fff, 0.5);
    margin: 2vh auto;
    border-radius: 4px;
    z-index: 10;
  }
}

@media screen and (min-width: 769px) {
  .hamburger {
    display: none !important;
  }
}
